import React from 'react'
import { motion } from 'framer-motion';
import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import Typewriter from 'typewriter-effect';


import './Header.scss';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}

const Header = () => {
  return (
    <div className='app__header app__flex'>
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >

        <div className='app__header-badge'>
          <div className='badge-cmp app__flex'>
            {/*<span>👋</span>*/}
            <div style={{ marginLeft: 20 }}>
              <Typewriter
                options={{
                  strings: ['<span class="p-text">Hola, soy </span><span class="head-text">Christopher</span> 👋',
                '<span class="p-text">Desarrollador Web </span><span class="head-text">Freelancer</span>'],
                  autoStart: true,
                  loop: true,
                  cursor: null,
                }}
              />
              {/*<p className='p-text'>
                Hola, Soy</p>
              <h1 className='head-text'>Christopher</h1>*/}
            </div>
          </div>

          {/*<div className='tag-cmp app__flex'>
            <p className='p-text'>Desarrollador Web</p>
            <p className='p-text'>Freelancer</p>
            </div>*/}

        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5, delayChilden: 0.5 }}
        className="app__header-img"
      >
        <img src={images.profile} alt="profile_bg" />
        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          src={images.circle}
          alt="profile_circle"
          className="overlay_circle"
        />

      </motion.div>

      <motion.div
        variant={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.sass, images.react, images.wordpress].map((circle, index) => (
          <div className='circle-cmp app__flex' key={`circle-${index}`}>
            <img src={circle} alt='circle' />
          </div>
        ))}
      </motion.div>
    </div>
  )
}

export default AppWrap(Header, 'inicio', 'app__whitebg');