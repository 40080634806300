import './Presentacion.scss'
import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import Typewriter from 'typewriter-effect';



const Presentacion = () => {

    return (
      <>
        <div className='app__presentacion app__flex'>
            <div className='text-zone'>
                  <Typewriter
                    options={{
                      strings: ['<span class="p-text"></span><span class="head-text">desarrollador front-end</span> <br> muy ambicioso con grandes ideas que busca la oportunidad de trabajar con las últimas tecnologias en proyectos desafiantes y diversos <br> <span class="p-text"> Soy tranquilo y confiado, naturalmente curioso y trabajo perpetuamente en mejorar mis habilidades.  </span> <br> <span class="head-text">"Un problema de diseño a la vez"</span>'],
                      autoStart: true,
                      loop: true,
                      delay: 40,
                      pauseFor: 100000,
                    }}
                  />

            </div>
            <div className='stage-cube-cont'>
                <div className='cubespinner'>
                    <div className='face1' >
                     <img src={images.javascript} alt="javascript_ico" />
                    </div>
                     <div className='face2' >
                     <img src={images.wordpress} alt="wordpress_ico" />
                    </div>
                    <div className='face3' >
                     <img src={images.git} alt="git_ico" />
                    </div>
                    <div className='face4' >
                      <img src={images.node} alt="node_ico" />
                    </div>
                    <div className='face5' >
                     <img src={images.react} alt="react_ico" />
                    </div>
                    <div className='face6' >
                      <img src={images.sass} alt="sass_ico" />
                    </div>
                </div>
            </div>

        </div>
        </>)
}

export default AppWrap(Presentacion, 'presentacion', "app__primarybg",);