/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */

import React from 'react'

const NavigationDots = ({ active }) => {
  return (
    <div className='app__navigation'>
      {['inicio', 'presentacion', 'conoceme', 'proyectos', 'habilidades', 'contacto'].map((item, index) => ( 
          <a 
            href={`#${item}`}
            key={item + index}
            className="app__navigation-dot"
            style={active === item ? { backgroundColor: '#E85855'} : {}} 
          />
      ))}
    </div>
  )
}

export default NavigationDots